.modal-content {
	font-family: 'Mulish', sans-serif;
	box-shadow: 3px 5px 40px 15px #666;
}
.close span {
	color: white;
	background: #bb2227;
	padding: 0.01rem 0.19rem 0.3rem 0.24rem;
	display: inline-block;
	border: none;
}
.close span:focus {
	border: none;
}

@media (max-width: 576px) {
	.modal-title {
		font-size: 1.2rem;
	}
	.close span {
		font-size: 1rem;
		margin-top: -1rem;
	}
	.modal-body {
		font-size: 0.9rem;
	}
}
